<template>
  <div>
    <div
      v-for="(option, i) in options"
      :key="`wz-radio-button-${i}`"
      class="wz-radio-item"
    >
      <input
        v-model="model"
        :name="name"
        :id="`${name}-${i}`"
        type="radio"
        :value="option.value"
      />
      <label :for="`${name}-${i}`">{{ option.text }}</label>
    </div>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: "MyInputFormRadioGold",
  props: ["name", "value", "options"],
  computed: {
    model: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
<style scoped>
.wz-radio-item input[type="radio"] {
  display: none;
}
.wz-radio-item + .wz-radio-item {
  margin-top: 15px;
}
.wz-radio-item label {
  display: block;
  padding-left: 25px;
  cursor: pointer;
  position: relative;
}
.wz-radio-item label:before,
.wz-radio-item label:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}
.wz-radio-item label:after {
  height: 18px;
  width: 18px;
  border: 2px solid #d89700;
  left: 0;
  top: calc(50% - 9px);
}

.wz-radio-item label:before {
  background: #d89700;
  height: 10px;
  width: 10px;
  left: 4px;
  top: calc(50% - 5px);
  transform: scale(3);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s ease-in-out 0s;
}
.wz-radio-item input[type="radio"]:checked ~ label:before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
</style>